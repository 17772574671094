import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",attrs:{"lazy-validation":""}},[_vm._l((_vm.formFields),function(field,i){return _c(VRow,{key:i},[_c(VCol,{staticClass:"py-0"},[_c('account-form-field',{attrs:{"item":_vm.item,"field":field,"rules":field.rules,"type":field.type,"hint":field.hint,"label":field.label,"isDisplayMode":!_vm.canModifyAccountDetails(_vm.account, _vm.user) || _vm.isDisplayMode,"isRequired":field.required,"isEditable":_vm.canModifyAccountDetails(_vm.account, _vm.user) && field.editable,"maxlength":field.maxlength,"accountStatus":_vm.account.status},on:{"update":_vm.updateField,"isValidating":_vm.setIsValidating}})],1)],1)}),(!_vm.isDisplayMode)?_c(VRow,{staticClass:"text-right"},[_c(VCol,[_c(VBtn,{staticClass:"mx-2",attrs:{"depressed":""},on:{"click":_vm.back}},[_vm._v(" Back to List ")]),(_vm.account.status !== 'DEPROVISIONED' && _vm.canModifyAccountDetails(_vm.account, _vm.user))?_c(VBtn,{staticClass:"mx-2",attrs:{"color":"secondary","loading":_vm.isSubmitting,"depressed":"","disabled":_vm.isValidating},on:{"click":_vm.validate}},[_vm._v(" Save Changes "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v("mdi-send")])],1):_vm._e()],1)],1):_vm._e(),_c(VSnackbar,{attrs:{"color":_vm.accountSnackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.accountSnackbar.value = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.accountSnackbar.value),callback:function ($$v) {_vm.$set(_vm.accountSnackbar, "value", $$v)},expression:"accountSnackbar.value"}},[_vm._v(" "+_vm._s(_vm.accountSnackbar.text)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }