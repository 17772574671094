<template>
  <v-container>
    <unsubscribe-from-service
      :unsubscribeFromServiceDialog.sync="unsubscribeFromServiceDialog"
      :username="account.username"
      :serviceName="selectedServiceName"
      :serviceId="selectedServiceId"
    ></unsubscribe-from-service>
    <send-service-activation-link
      :sendServiceActivationLinkDialog.sync="sendServiceActivationLinkDialog"
      :username="account.username"
      :serviceName="selectedServiceName"
      :serviceId="selectedServiceId"
    ></send-service-activation-link>
    <v-card
      flat
      outlined
      class="my-2"
      v-for="accountService in accountServices"
      :key="accountService.id"
    >
      <v-card-title>
        {{ accountService.name }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="accountService.requiresActivation && getAccountServiceStatusAsync(account.username, accountService.id)"
          :disabled="account.status === 'DEPROVISIONED'"
          plain
          @click.stop="sendServiceActivationLink(account.username, accountService.id, accountService.name)"
        >
        Send activation link
        </v-btn>
        <v-btn
          class="mx-2"
          color="primary"
          :disabled="account.status === 'DEPROVISIONED' || !canUnsubscribeAccountFromService(account, user)"
          plain
          @click.stop="unsubscribe(accountService.id, accountService.name)"
        >
          Unsubscribe
        </v-btn>
      </v-card-title>
      <v-card-subtitle>{{ accountService.description }}</v-card-subtitle>
    </v-card>
    <v-btn
      block
      color="primary"
      :disabled="account.status === 'DEPROVISIONED' || !canRequest(account, user)"
      large
      @click="requestForServiceSubscription(account.username)"
    >
      Subscribe to new service
    </v-btn>
    <v-snackbar v-model="serviceSnackbar.value" :color="serviceSnackbar.color">
      {{ serviceSnackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="serviceSnackbar.value = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useAccountStore } from '@/store/account'
import { useServiceStore } from '@/store/service'
import { useUserStore } from '@/store/user'
import UnsubscribeFromService from '@/components/Dialogs/UnsubscribeFromService.vue'
import SendServiceActivationLink from '@/components/Dialogs/SendServiceActivationLink.vue'
import AccountMixin from '@/mixins/account.mixin'

export default {
  name: 'Services',
  components: {
    UnsubscribeFromService,
    SendServiceActivationLink
  },
  mixins: [AccountMixin],
  data() {
    return {
      selectedServiceId: null,
      selectedServiceName: null,
      unsubscribeFromServiceDialog: false,
      sendServiceActivationLinkDialog: false
    }
  },
  computed: {
    ...mapState(useAccountStore, ['account', 'accountServices']),
    ...mapState(useServiceStore, ['service', 'serviceSnackbar']),
    ...mapState(useUserStore, ['user'])
  },
  watch: {
    async unsubscribeFromServiceDialog(val) {
      if (!val) {
        await this.refetchAccountServicesAsync()
      }
    }
  },
  methods: {
    unsubscribe(serviceId, serviceName) {
      this.unsubscribeFromServiceDialog = true
      this.selectedServiceId = serviceId
      this.selectedServiceName = serviceName
    },
    sendServiceActivationLink(username, serviceId, serviceName) {
      this.sendServiceActivationLinkDialog = true
      this.selectedServiceId = serviceId
      this.selectedServiceName = serviceName
    },
    async getAccountServiceStatusAsync(username, serviceName, requiresActivation) {
      if (requiresActivation) {
        await this.getUserServiceStatusAsync(username, serviceName)
        .then((res) => {
          if (res === 'PendingAcceptance')
            return true
          else
            return false
        })
      } else {
        return false
      }
    },
    async refetchAccountServicesAsync() {
      await this.fetchAccountServicesAsync(this.$route.params.login).then((res) => {
        this.isLoading = false
        if (!res.success) {
          switch (res.error.response.status) {
            case 404: {
              this.$router.push({ name: 'not-found' })
              break
            }
            case 403: {
              this.$router.push({ name: 'forbidden' })
              break
            }
            default: {
            }
          }
        }
      })
    },
    requestForServiceSubscription(guestAccountUsername) {
      this.$router.push({
        name: 'create-request',
        params: { guestAccountUsername, type: 'SERVICE_SUBSCRIPTION' }
      })
    },
    ...mapActions(useAccountStore, ['fetchAccountServicesAsync']),
    ...mapActions(useServiceStore, ['getUserServiceStatusAsync'])
  },
  async mounted() {
    this.refetchAccountServicesAsync()
  }
}
</script>
