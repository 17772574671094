<template>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="overline primary--text font-weight-medium">
          Send service activation link confirmation
        </v-card-title>
        <v-card-text class="text-center">
          <p>Are you sure you want to send {{ serviceName }} activation link for this guest?</p>
          <p>
            <span class="font-weight-medium secondary--text">{{ username }}</span>
          </p>
          <p>
            The service activation link will be sent to your guest's registered email
            address.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn @click="dialog = false" depressed :disabled="isSending">
            No
          </v-btn>
  
          <v-btn @click="send" color="secondary" depressed :loading="isSending">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { useServiceStore } from '@/store/service'
  import { mapActions } from 'pinia'
  
  export default {
    name: 'SendServiceActivationLink',
    props: {
      username: { type: String },
      serviceId: { type: String },
      serviceName: { type: String },
      sendServiceActivationLinkDialog: { type: Boolean }
    },
    data() {
      return {
        isSending: false
      }
    },
    computed: {
      dialog: {
        get() {
          return this.sendServiceActivationLinkDialog
        },
        set(v) {
          this.$emit('update:sendServiceActivationLinkDialog', v)
        }
      }
    },
    methods: {
      async send() {
        this.isSending = true
        await this.sendServiceActivationLinkAsync(this.serviceId, this.username).finally(() => {
          this.isSending = false
          this.dialog = false
        })
      },
      ...mapActions(useServiceStore, ['sendServiceActivationLinkAsync'])
    }
  }
  </script>