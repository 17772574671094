<template>
  <v-form ref="form" lazy-validation>
    <v-row v-for="(field, i) in formFields" :key="i">
      <v-col class="py-0">
        <account-form-field
          :item="item"
          :field="field"
          :rules="field.rules"
          :type="field.type"
          :hint="field.hint"
          :label="field.label"
          :isDisplayMode="!canModifyAccountDetails(account, user) || isDisplayMode"
          :isRequired="field.required"
          :isEditable="canModifyAccountDetails(account, user) && field.editable"
          :maxlength="field.maxlength"
          :accountStatus="account.status"
          @update="updateField"
          @isValidating="setIsValidating"
        >
        </account-form-field>
      </v-col>
    </v-row>

    <v-row v-if="!isDisplayMode" class="text-right">
      <v-col>
        <v-btn class="mx-2" depressed @click="back">
          Back to List
        </v-btn>
        <v-btn
          v-if="account.status !== 'DEPROVISIONED' && canModifyAccountDetails(account, user)"
          class="mx-2"
          color="secondary"
          :loading="isSubmitting"
          depressed
          @click="validate"
          :disabled="isValidating"
        >
          Save Changes
          <v-icon right dark>mdi-send</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="accountSnackbar.value" :color="accountSnackbar.color">
      {{ accountSnackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="accountSnackbar.value = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import AccountFormField from '@/components/AccountFormField.vue'
import { useAccountStore } from '@/store/account'
import { useUserStore } from '@/store/user'
import { mapActions } from 'pinia'
import { mapState } from 'pinia'
import AccountMixin from '@/mixins/account.mixin'

export default {
  props: {
    isDisplayMode: Boolean,
    account: Object
  },
  name: 'AccountForm',
  components: { AccountFormField },
  mixins: [AccountMixin],
  data() {
    return {
      isSubmitting: false,
      isValidating: false,
      timeout: 3000
    }
  },
  computed: {
    item() {
      let item = {
        fields: []
      }
      for (let f of this.formFields) {
        if (this.account) {
          if (
            f.name === 'sponsorEmployeeNumber' ||
            f.name === 'sponsorOrgCode'
          ) {
            this.$set(item.fields, f.name, _.get(this.account, f.name))
          } else {
            this.$set(item.fields, f.name, _.get(this.account, f.name))
          }
        } else {
          this.$set(item.fields, f.name, null)
        }
      }
      return item
    },
    formFields() {
      return this.accountFields
    },
    ...mapState(useAccountStore, ['accountSnackbar', 'accountFields']),
    ...mapState(useUserStore, ['user'])
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    setIsValidating(v) {
      this.isValidating = v
    },
    async submit() {
      this.isSubmitting = true
      await this.updateAccountAsync(this.account.id, this.item)
        .then((res) => {
          if (res) {
            setTimeout(() => {
              this.$router.push({ name: 'list-accounts' })
            }, this.timeout)
          }
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    updateField(fieldObject) {
      if (!_.isEqual(this.item.fields[fieldObject.name], fieldObject.value)) {
        this.$set(this.item.fields, fieldObject.name, fieldObject.value)
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        await this.submit()
      }
    },
    ...mapActions(useAccountStore, ['updateAccountAsync'])
  }
}
</script>

<style scoped>
.mdi-send::before {
  transform: rotate(-25deg);
  margin-top: -5px;
}
</style>