<template>
  <v-skeleton-loader
    v-if="isLoading"
    class="mx-auto"
    max-width="800"
    type="card"
  >
  </v-skeleton-loader>
  <v-container v-else>
    <v-card flat color="transparent">
      <!-- dialogs start -->
      <reset-factors
        :resetFactorsDialog.sync="resetFactorsDialog"
        :username="account.username"
      ></reset-factors>
      <send-password-reset-link
        :sendPasswordResetLinkDialog.sync="sendPasswordResetLinkDialog"
        :username="account.username"
      ></send-password-reset-link>
      <send-activation-link
        :sendActivationLinkDialog.sync="sendActivationLinkDialog"
        :username="account.username"
      ></send-activation-link>
      <deactivate-account
        :deactivateAccountDialog.sync="deactivateAccountDialog"
        :username="account.username"
      ></deactivate-account>
      <!-- dialogs end -->
      <v-card-subtitle>Guest Account</v-card-subtitle>
      <v-card-title class="pt-0 text-h6" v-if="account">
        {{ account.username }}
        <v-spacer></v-spacer>
        <account-status-chip :status="account.status"></account-status-chip>

        <v-menu v-if="hasAccessToAccount(account, user)" bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- Reset factors button -->
            <v-list-item v-if="account.status !== 'DEPROVISIONED'"
              @click.stop="resetFactorsDialog = true"
            >
              <v-icon left>mdi-lock-remove</v-icon>
              Reset multifactor
            </v-list-item>
            
            <!-- Send password reset link button -->
            <v-list-item
              v-if="
                account.status === 'ACTIVE' ||
                account.status === 'RECOVERY' ||
                account.status === 'PASSWORD_EXPIRED'
              "
              @click.stop="sendPasswordResetLinkDialog = true"
            >
              <v-icon left>mdi-lock-reset</v-icon>
              Send password reset link
            </v-list-item>

            <!-- Send activation link button -->
            <v-list-item
              v-if="account.status === 'PROVISIONED'"
              @click.stop="sendActivationLinkDialog = true"
            >
              <v-icon left>mdi-account-arrow-right</v-icon>
              Send activation link
            </v-list-item>

            <!-- Deactivate button -->
            <v-list-item 
              v-if="canDeactivateAccount(account, user) && account.status !== 'DEPROVISIONED'"
              @click.stop="deactivateAccountDialog = true"
            >
              <v-icon left>mdi-account-off</v-icon>
              Deactivate
            </v-list-item>

            <!-- Request for account expiry extension button -->
            <v-list-item
              v-if="canRequest(account, user) && isExpiring(account.accountExpiry) && account.status !== 'DEPROVISIONED'"
              @click="requestForAccountExpiryExtension(account.username)"
            >
              <v-icon left>mdi-timer-plus</v-icon>
              Request for account expiry extension
            </v-list-item>

            <!-- Request for account reactivation button -->
            <v-list-item
              v-if="canRequest(account, user) && ((account.status === 'SUSPENDED' && !isExpiring(account.accountExpiry)) || account.status === 'DEPROVISIONED')"
              @click="requestForAccountReactivation(account.username)"
            >
              <v-icon left>mdi-account-reactivate</v-icon>
              Request for account reactivation
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
    </v-card>
    <v-card flat>
      <v-tabs v-model="tab">
        <v-tab key="guestInformation">Guest Information</v-tab>
        <v-tab key="serviceSubscriptions">Service Subscriptions</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-card-text>
          <v-tab-item key="guestInformation">
            <account-form v-if="account" :account="account"></account-form>
          </v-tab-item>
          <v-tab-item key="serviceSubscriptions">
            <services v-if="account" :account="account"></services>
          </v-tab-item>
        </v-card-text>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useAccountStore } from '@/store/account'
import { useUserStore } from '@/store/user'
import AccountStatusChip from '@/components/AccountStatusChip.vue'
import ResetFactors from '@/components/Dialogs/ResetFactors.vue'
import SendPasswordResetLink from '@/components/Dialogs/SendPasswordResetLink.vue'
import SendActivationLink from '@/components/Dialogs/SendActivationLink.vue'
import DeactivateAccount from '@/components/Dialogs/DeactivateAccount.vue'
import AccountMixin from '@/mixins/account.mixin'

import AccountForm from '@/components/AccountForm.vue'
import Services from '@/components/Services.vue'

export default {
  name: 'AccountDetails',
  components: {
    AccountForm,
    AccountStatusChip,
    SendPasswordResetLink,
    SendActivationLink,
    DeactivateAccount,
    ResetFactors,
    Services
  },
  mixins: [AccountMixin],
  data() {
    return {
      isLoading: true,
      resetFactorsDialog: false,
      sendPasswordResetLinkDialog: false,
      sendActivationLinkDialog: false,
      deactivateAccountDialog: false,
      tab: null
    }
  },
  computed: {
    ...mapState(useAccountStore, ['account']),
    ...mapState(useUserStore, ['user'])
  },
  watch: {
    async resetFactorsDialog(val) {
      if (!val) {
        await this.refetchAccount()
      }
    },
    async sendPasswordResetLinkDialog(val) {
      if (!val) {
        await this.refetchAccount()
      }
    },
    async sendActivationLinkDialog(val) {
      if (!val) {
        await this.refetchAccount()
      }
    },
    async deactivateAccountDialog(val) {
      if (!val) {
        await this.refetchAccount()
      }
    }
  },
  methods: {
    requestForAccountExpiryExtension(guestAccountUsername) {
      this.$router.push({
        name: 'create-request',
        params: { guestAccountUsername, type: 'ACCOUNT_EXPIRY_EXTENSION' }
      })
    },
    requestForAccountReactivation(guestAccountUsername) {
      this.$router.push({
        name: 'create-request',
        params: { guestAccountUsername, type: 'ACCOUNT_REACTIVATION' }
      })
    },
    async refetchAccount() {
      this.isLoading = true
      await this.fetchAccountAsync(this.account.username).then((res) => {
        this.isLoading = false
      })
    },
    ...mapActions(useAccountStore, ['fetchAccountAsync'])
  },
  async mounted() {
    this.isLoading = true
    await this.fetchAccountAsync(this.$route.params.login).then((res) => {
      this.isLoading = false
      if (!res.success) {
        switch (res.error.response.status) {
          case 404: {
            this.$router.push({ name: 'not-found' })
            break
          }
          case 403: {
            this.$router.push({ name: 'forbidden' })
            break
          }
          default: {
          }
        }
      }
    })
  }
}
</script>

<style scoped>
.theme--light.v-card {
  color: #192b40;
}

.theme--light.v-card > .v-card__subtitle {
  color: #192b40;
}
</style>
